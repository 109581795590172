<template>
  <div>
    <a-upload
      v-model:file-list="fileList"
      :name="name"
      accept="image/png,image/jpeg"
      class="avatar-uploader"
      :class="{ hasImage: !!imageUrl }"
      :show-upload-list="false"
      :action="action"
      :headers="headers"
      :before-upload="beforeUpload"
      :disabled="isDisabled"
      @change="handleChange"
    >
      <div v-if="imageUrl" class="preview">
        <img :src="imageUrl" alt="avatar" />
        <span><EditFilled class="has-text-primary" /></span>
      </div>
      <div v-else>
        <LoadingOutlined v-if="loading" />
        <IconImage v-else />
        <div class="ant-upload-text">
          {{ buttonTextT }}
        </div>
      </div>
    </a-upload>
  </div>
</template>

<i18n>
{
"en": {
"Upload":"Upload"
},
"ja": {
"Upload":"アップロード"
}
}
</i18n>

<script>
import { LoadingOutlined, EditFilled } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { getBase64 } from '@/utils/util';
import IconImage from '@/components/icons/IconImage';
// eslint-disable-next-line vue/require-direct-export
export default defineComponent({
  name: 'UploadSingleImage',
  components: {
    LoadingOutlined,
    EditFilled,
    IconImage,
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
    },
    action: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: 'images',
    },
    headers: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:value', 'change'],
  setup(props, { emit }) {
    const fileList = ref([]);
    const loading = ref(false);
    const imageUrl = ref('');
    const buttonTextT = ref(props.buttonText ? props.buttonText : useI18n().t('Upload'));

    const handleChange = (info) => {
      if (info.file.status === 'uploading') {
        loading.value = true;
        return;
      }

      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (base64Url) => {
          imageUrl.value = base64Url;
          loading.value = false;
        });
        emit('change', { url: info.file.originFileObj.url, base64: imageUrl });
        emit('update:value', info.file.originFileObj.url);
      }

      if (info.file.status === 'error') {
        loading.value = false;
        message.error('upload error');
      }
    };

    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      const isSizeFit = file.size < 10 * 1024 * 1024;

      if (!isSizeFit) {
        message.error('Image must smaller than 10MB!');
      }
      if (!isJpgOrPng) {
        message.error('You can only upload JPG or PNG file!');
      }

      return isJpgOrPng && isSizeFit;
    };

    return {
      fileList,
      loading,
      imageUrl,
      handleChange,
      beforeUpload,
      buttonTextT,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.imageUrl = nv;
        }
      },
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep .avatar-uploader > .ant-upload {
  width: 80px !important;
  height: 80px !important;
  margin: 0 !important;
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #00596c;
  color: #00596c !important;
  user-select: none;
  line-height: 1 !important;
  @include mobile {
    width: 50px !important;
    height: 50px !important;
  }
}

::v-deep .avatar-uploader.hasImage > .ant-upload {
  border: none;
}

::v-deep .avatar-uploader i {
  font-size: 32px;
  color: #999;
  @include mobile {
    font-size: 12px;
  }
}

::v-deep .avatar-uploader .ant-upload-text {
  margin-top: 2px;
  color: #666;
  @include mobile {
    margin-top: -5px;
    font-size: 12px;
  }
}

::v-deep .preview {
  width: 80px;
  height: 80px;
  object-fit: cover;
  overflow: hidden;
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  }
}
</style>
